import React from 'react'
import { Row, Col, Hidden } from 'react-grid-system'

import DesktopTimer from 'images/desktop-timer.png'
import PiggyBank from 'images/piggy-bank.png'
import SaveMoneyLady from 'images/save-money-lady.jpg'
import ShoppingCart from 'images/shopping-cart.png'

import styles from './SaveMoney.module.scss'

import Button from '../Button'
import Layout from '../Layout'

const SaveMoney = ({ currentUser, categories }) => {
  return (
    <Layout>
      <Row>
        <Col>
          <div className={styles.saveMoneyHeader}>
            <div className={styles.saveMoneyHeaderContent}>
              <h2 className={styles.heading}>Get Cash Back while shopping at your favorite stores!</h2>

              {!currentUser && (
                <div className={styles.button}>
                  <Button variant="primary" href="/account/sign-up">Join Now for Free</Button>
                </div>              
              )}

              {currentUser && (
                <div className={styles.button}>
                  <Button variant="primary" href="/stores">Shop Now</Button>
                </div>
              )}
            </div>
            <Hidden xs>
              <div className={styles.saveMoneyHeaderImage}>
                <img src={SaveMoneyLady} />
              </div>
            </Hidden>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className={styles.howItWorksContainer}>
            <div className={styles.howItWorksHeading}>
              <h2 className={styles.heading}>How it Works</h2>
            </div>

            <div className={styles.howItWorksContent}>
              <div className={styles.howItWorksStep}>
                {!currentUser && (
                  <h3>1. Sign Up</h3>
                )}

                {currentUser && (
                  <h3>1. Sign In</h3>
                )}

                <div className={styles.howItWorksImage}>
                  <img src={DesktopTimer} />
                </div>
                
                <div className={styles.howItWorksDescription}>
                  <p>It&apos;s quick, easy, and always <em>free</em> to use.</p>
                </div>
              </div>

              <div className={styles.howItWorksStep}>
                <h3>2. Shop</h3>
                
                <div className={styles.howItWorksImage}>
                  <img src={ShoppingCart} />
                </div>
                
                <div className={styles.howItWorksDescription}>
                  <p>Choose your favorite store on Buygoon.</p>
                </div>
              </div>

              <div className={styles.howItWorksStep}>
                <h3>3. Save Money</h3>

                <div className={styles.howItWorksImage}>
                  <img src={PiggyBank} />
                </div>
                
                <div className={styles.howItWorksDescription}>
                  <p>You get cash back when you shop at your favorite stores.</p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className={styles.saveMoneyCategoriesHeader}>
            <h3 className={styles.makeShopping}>Make Shopping More Rewarding!</h3>
          </div>
        </Col>
      </Row>
      
      <div className={styles.saveMoneyCategories}>
        <Row>
          <Col lg={8}>
            <div className={styles.categoriesList}>
              {categories.map((category) => {
                return (
                  <a key={category.name} href={`/categories/${category.slug}`}>
                    <div className={styles.category}>
                      <div className={styles.categoryImage}>
                        <img src={`${category.thumbnail_url || 'https://picsum.photos/220/80'}`} />
                      </div>
                    </div>

                    <div className={styles.cardContainer}>
                      <div className={styles.categoryName}>
                        {category.name}
                      </div>
                    </div>
                  </a>
                )
              })}
            </div>
          </Col>

          <Col lg={4}>
            <div className={styles.saveMoneyContent}>
              <h3 className={styles.makeMoneySuperSimple}>
                We make
                <br /><span className={styles.colorChange}>saving money<br />super simple</span>.
                <br />
                <br />You&apos;ll earn cash back
                <br />on a wide variety of 
                <br />products and services like
                <br />Home & Garden,
                <br />and Pet Supplies,
                <br />and Baby Clothes,
                <br />and Travel,
                <br />and ...
              </h3>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            {!currentUser && (
              <div className={styles.button}>
                <Button variant="primary" href="/account/sign-up">Join Now to Save Money</Button>
              </div>
            )}

            {currentUser && (
              <div className={styles.button}>
                <Button variant="primary" href="/stores">Start Saving Money Now</Button>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default SaveMoney
