import React from 'react'
import { Col, Container, Hidden, Row, Visible } from 'react-grid-system'

import styles from './Footer.module.scss'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <Hidden xs sm>
          <Row>
            <Col md={3} lg={3}>
              <h3 className={styles.footerHeading}>SHOP</h3>

              <ul>
                <li>
                  <a href="/stores" className={styles.footerLink}>
                    Stores
                  </a>
                </li>
                <li>
                  <a href="/categories" className={styles.footerLink}>
                    Categories
                  </a>
                </li>
                <li>
                  <a href="/" className={styles.footerLink}>
                    Home
                  </a>
                </li>
              </ul>
            </Col>

            <Col md={3} lg={3}>
              <h3 className={styles.footerHeading}>ACCOUNT</h3>

              <ul>
                <li>
                  <a href="/account/my-cash" className={styles.footerLink}>
                    My Cash
                  </a>
                </li>
                <li>
                  <a href="/account/my-purchases" className={styles.footerLink}>
                    My Purchases
                  </a>
                </li>
                <li>
                  <a href="/account/my-network" className={styles.footerLink}>
                    My Network
                  </a>
                </li>
                <li>
                  <a href="/account/my-referral-codes" className={styles.footerLink}>
                    My Referral Codes
                  </a>
                </li>
                <li>
                  <a href="/account/my-profile" className={styles.footerLink}>
                    My Profile
                  </a>
                </li>
              </ul>
            </Col>

            <Col md={3} lg={3}>
              <h3 className={styles.footerHeading}>ABOUT</h3>

              <ul>
                <li>
                  <a href="/make-money" className={styles.footerLink}>
                    Make Money
                  </a>
                </li>
                <li>
                  <a href="/save-money" className={styles.footerLink}>
                    Save Money
                  </a>
                </li>
                <li>
                  <a href="/about" className={styles.footerLink}>
                    About Buygoon
                  </a>
                </li>
                <li>
                  <a href="https://blog.buygoon.com/" className={styles.footerLink}>
                    Blog
                  </a>
                </li>
              </ul>
            </Col>
            
            <Col md={3} lg={3}>
              <h3 className={styles.footerHeading}>SUPPORT</h3>

              <div className={styles.support}>
                <ul>
                  <li>
                    <h3>Please contact us with any questions:</h3>

                    <a className={styles.mailToLink} href="mailto:support@buygoon.com">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg>

                      <span>support@buygoon.com</span>
                    </a>
                  </li>
                  <li>
                    <a href="/privacy-policy" className={styles.footerLink}>
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="/terms-and-conditions" className={styles.footerLink}>
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li>
                  <a href="/help" className={styles.footerLink}>
                    Help
                  </a>
                </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Hidden>

        <Visible xs sm>
          <Row>
            <Col xs={6} sm={6}>
              <h3 className={styles.footerHeading}>SHOP</h3>

              <ul>
                <li>
                  <a href="/stores" className={styles.footerLink}>
                    Stores
                  </a>
                </li>
                <li>
                  <a href="/categories" className={styles.footerLink}>
                    Categories
                  </a>
                </li>
                <li>
                  <a href="/" className={styles.footerLink}>
                    Home
                  </a>
                </li>
              </ul>
            </Col>

            <Col xs={6} sm={6}>
              <h3 className={styles.footerHeading}>ACCOUNT</h3>

              <ul>
                <li>
                  <a href="/account/my-cash" className={styles.footerLink}>
                    My Cash
                  </a>
                </li>
                <li>
                  <a href="/account/my-purchases" className={styles.footerLink}>
                    My Purchases
                  </a>
                </li>
                <li>
                  <a href="/account/my-network" className={styles.footerLink}>
                    My Network
                  </a>
                </li>
                <li>
                  <a href="/account/my-referral-codes" className={styles.footerLink}>
                    My Referral Codes
                  </a>
                </li>
                <li>
                  <a href="/account/my-profile" className={styles.footerLink}>
                    My Profile
                  </a>
                </li>
              </ul>
            </Col>
          </Row>

          <div className={styles.bottomFooterContainer}>
            <Row>
              <Col xs={6} sm={6}>
                <h3 className={styles.footerHeading}>ABOUT</h3>

                <ul>
                  <li>
                    <a href="/make-money" className={styles.footerLink}>
                      Make Money
                    </a>
                  </li>
                  <li>
                    <a href="/save-money" className={styles.footerLink}>
                      Save Money
                    </a>
                  </li>
                  <li>
                    <a href="/about" className={styles.footerLink}>
                      About Buygoon
                    </a>
                  </li>
                  <li>
                    <a href="https://blog.buygoon.com/" className={styles.footerLink}>
                      Blog
                    </a>
                  </li>
                </ul>
              </Col>
              
              <Col xs={6} sm={6}>
                <h3 className={styles.footerHeading}>SUPPORT</h3>

                <div className={styles.support}>
                  <ul>
                    <li>
                      <h3>Please contact us</h3>
                      <h3>with any questions:</h3>

                      <a className={styles.mailToLink} href="mailto:support@buygoon.com">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>

                        <span>support@buygoon.com</span>
                      </a>
                    </li>
                    <li>
                      <a href="/privacy-policy" className={styles.footerLink}>
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="/terms-and-conditions" className={styles.footerLink}>
                        Terms &amp; Conditions
                      </a>
                    </li>
                    <li>
                      <a href="/help" className={styles.footerLink}>
                        Help
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Visible>

        <Row>
          <Col>
            <div className={styles.spacer} />
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.subFooter}>
              <div className={styles.registeredContainer}>
                <h2>Buygoon <span className={styles.registered}>&reg;</span></h2>

                <span className={styles.byline}>Make Shopping More Rewarding &trade;</span>

                <Visible xs sm>
                  <div>
                    <p>© 2024 Buygoon Inc.</p>
                  </div>
                </Visible>
              </div>

              {/* Borrowed from: https://tailwind-elements.com/docs/standard/components/social-buttons */}
              <ul className={styles.social}>
                <li>
                  <a href="https://www.facebook.com/buygoon/" className={styles.fb} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24">
                      <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                    </svg>
                  </a>
                </li>

                <li>
                  <a href="https://x.com/buygoon" className={styles.twitter} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 -25 512 512">
                      <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                    </svg>
                  </a>
                </li>

                <li>
                  <a href="https://www.instagram.com/buygoon/?hl=en" className={styles.instagram} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="-1 -2 26 26">
                      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                    </svg>
                  </a>
                </li>
              </ul>

              <Hidden xs sm>
                <div>
                  <h3>
                    © 2024 Buygoon Inc.
                  </h3>
                </div>
              </Hidden>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer