import React from 'react'

import styles from './HotDeals.module.scss'

import Button from '../Button'

const HotDealsListItem = ({ couponDetails }) => {
  return (
    <div className={styles.hotDealsListItem}>
      {couponDetails && (
        <div className={styles.hotDealImage}>
          <img src={`${couponDetails && couponDetails.logo_url ? couponDetails.logo_url : 'https://picsum.photos/70/41'}`} />
        </div>
      )}

      <div className={styles.hotDealInfo}>
        <h3>{couponDetails.short_description}</h3>

        <div className={styles.cashbackContainer}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
          </svg>

          <h3 className={styles.cashback}>{couponDetails.display_commission_string_html}</h3>
          
          {couponDetails && couponDetails.coupon_link_info && couponDetails.coupon_link_info.impression_pixel && (
            <img src={couponDetails.coupon_link_info.impression_pixel} />
          )}          
        </div>
      </div>

      <div className={styles.button}>
        <Button variant="secondary" href={couponDetails.coupon_link_path}>Shop Deal</Button>
      </div>
    </div>
  )
}

export default HotDealsListItem