import React from 'react'

import styles from './SearchInput.module.scss'

import Button from '../Button'
import Input from '../Input'

const SearchInput = ({
  value,
  onChange,
  onClick,
}) => {
  return (
    <Input
      id="query"
      type="text"
      value={value}
      onChange={onChange}
      placeholder="Search cash back stores"
      after={
        <Button
          type="submit"
          className={styles.button}
          variant="primary"
          aria-label="Search"
          onClick={onClick}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </Button>
      }
    />
  )
}

export default SearchInput
