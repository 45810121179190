import React from 'react'
import { Row, Col } from 'react-grid-system'

import styles from './Help.module.scss'

import Layout from '../Layout'
import PageHeading from '../PageHeading'

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion'

import '@reach/accordion/styles.css'

const Help = () => {
  return (
    <Layout>
      <Row>
        <Col>
          <PageHeading>
            <h1>Help</h1>
          </PageHeading>

          <div className={styles.accordionContainer}>
            <h2>About Buygoon</h2>

            <Accordion collapsible className={styles.accordion}>
              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  Why is Buygoon such a great thing?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Because you can earn money in the huge online shopping industry, without spending a penny to do it.</p>
                  <p>Buygoon permits you to save money, when you want to shop online. And of course, "saving" is a good thing. But no matter how good saving money is, it is not as good as "earning" money.</p>
                  <p>So Buygoon created a way for you to actually earn money utilizing your social network and Buygoon's cash back deals, coupons and sales.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  What is Buygoon and why should I join?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>We are a unique online platform that lets you both
                    <span className={styles.italic}> save <span className={styles.underline}>and</span> earn money </span>
                    with online shopping!
                  </p>
                  <p>You save money while shopping with our great deals, coupons and cash back.</p>
                  <p>You earn money by getting paid cash bonuses whenever anyone in your network shops on Buygoon and earns cash back. Depending on how large you grow your network, you could get paid a cash bonus on the cash back earned by potentially thousands of people in your network that shop online with us.</p>
                  <p>And since our model is patent pending, you won't find our exclusive savings and earnings combination anywhere else.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  How much do I save when I shop?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>
                    <span className={styles.bold}> With deals, coupons & sales:</span><br/>
                    Our store partners offer Buygoon coupons, special deals and sales on almost a daily basis, that we pass along to you. When you click on a store on Buygoon, you will be taken to that store's website. You can then shop directly on their website and enjoy the deal, coupon or sale offered by that store.
                  </p>
                  <p>
                    <span className={styles.bold}>With cash back:</span><br/>
                    In addition to saving with deals, coupons and sales, Buygoon partners with various brand name stores that pay us cash back when you click through Buygoon to make a qualifying purchase on those stores' websites. We split that cash back with you 50/50. Stores set the amount of cash back, which can range from 1% to 40% of what you spend shopping with them. The amount of cash back offered by a store is shown with our advertisers' or products' logos. And while the percentage of cash back varies across different stores and their products, we always share ½ of the cash back we receive with you.
                  </p>
                  <p>And yes, to be clear, we pay the cash back in addition to the deal, coupon or sale you enjoyed with the store.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  How much cash bonus do I earn from my network?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Buygoon will pay you 10% of the cash back earned by ALL of the people in your downstream network. For instance, if someone in your network earns $120 cash back on their purchase, you earn a $12 cash bonus. It's that simple.</p>
                  <p>Read below to see how you could be earning 10% cash bonus on thousands of online shoppers!</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  Can I shop at major stores through Buygoon?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Definitely. We partner with a vast number of brand name stores.</p>
                  <p>Simply join Buygoon (it's free!) and sign in. Then click your desired store logo or advertisement shown on our site. You will be navigated in a new browser window to that store's website, to shop and pay directly on their website, just like you otherwise normally do.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  How do I find stores, coupons and deals I want?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Simply log in to your Buygoon account, browse by Store or Category, or use our search bar to find the store you want.</p>
                  <p>When you find the store, click on it and you will be redirected onto the store's own website where you shop and pay the store directly as you normally would.</p>
                  <p>We offer a great variety of quality stores with excellent goods and services and work daily to expand our list of retail partners. If you would like to recommend a store not currently on Buygoon, simply click Contact Us and tell us. We will do everything we can to add your store.</p>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>

          <div className={styles.accordionContainer}>
            <h2>Signing up With Buygoon</h2>

            <Accordion collapsible className={styles.accordion}>
              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  Is there any charge to join Buygoon?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>No. Our membership is totally free, and there are no monthly charges or minimum spending requirements either!</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  How do I sign up with Buygoon?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Simply enter your email address and choose a password on the sign up page.</p>
                  <p>If you received an email or link from your friend, click that link or post,  which will take you to Buygoon’s sign up page. If someone told you about Buygoon, but you didn't use their personal link, we encourage you to enter their email address as your Referring Friend when you sign up.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  Why should I use my Referring Friend’s email or link when I sign up?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>To benefit yourself and your friend. The whole point of Buygoon is for friends, and friends of friends, to expand their network to maximize their cash back bonus potential while enjoying and sharing great products and deals.</p>
                  <p>If you receive a custom link from your friend (which you will also be able to generate and send to your friends once you sign up) you should use it, so our custom software can automatically place you in your friend's network.</p>
                  <p>There is nothing to be gained by not entering your friend’s email. If you do not enter an email of a friend that is a current member of Buygoon, our system will randomly enter you in the network under an existing Buygoon member.</p>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>

          <div className={styles.accordionContainer}>
            <h2>Your Downstream Network & Cash Bonuses</h2>

            <Accordion collapsible className={styles.accordion}>
              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  How does my downstream network grow?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Simply refer your friends and tell them to refer their friends, and their friends, and so on and so on. You can send your friends your unique Buygoon referral link by email, or post it to your Facebook, Twitter, or other social network accounts, or to your newsletter, blog or website. Alternatively, simply  ask your friends to enter your email address as the Referring Friend when they sign up.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  Where do I find my unique Buygoon referral link?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>
                    <span>When signed in to your account, in the top right corner, click on your name and then </span>
                    <span className={styles.bold}>My Referral Codes.</span>
                  </p>
                  <p>
                    <span>All of your personalized referral codes will be visible and ready for you to share. The codes will look like this: [</span>
                    <span className={styles.italic}>https://www.buygoon.com/r/XXXXXX</span>
                    <span>], where XXXXXX is your unique personal referral code.</span>
                  </p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  How big can my downstream network grow?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>It could be huge. We don’t limit how big your network can grow. It could contain thousands of people earning you cash bonuses. It depends on how many friends you refer and how many friends your downstream network refers.</p>
                  <p>You can invite as many friends as you want. Then tell your friends to pass it on to all their friends, to pass on, and so on.</p>
                  <p>
                    Buygoon’s proprietary software will trace your downstream network of referred members up to 4 layers of separation from you. That means you will earn a 10% cash bonus on all the cash back earned by your referrals, their referrals, their referrals and their referrals.
                    <span className={styles.italic}> Please be aware that Buygoon has a strict anti-spam policy.</span>
                  </p>
                  <p>As just one example of how huge your network could grow by you inviting only a few people, let’s assume 10 of your referred friends join Buygoon, and they all refer 10 of their friends, and so on. If that happens, your downstream network would contain 11,110 people earning you cash back bonuses when they shop online with Buygoon!</p>
                  <p className={styles.bold}>Here’s how it works:</p>
                    <p>
                      You refer 10 friends. These 10 referrals make up your
                      <span className={styles.bold}> 1st network layer</span>.
                    </p>
                    <p>
                      Then each of your 10
                      <span className={styles.bold}> 1st network layer </span>
                      members refers 10 new friends. These 100 referrals make up your
                      <span className={styles.bold}> 2nd network layer</span>.
                    </p>
                    <p>
                      Then each of your 100
                      <span className={styles.bold}> 2nd network layer </span>
                      members refers 10 new friends. These 1,000 referrals make up your
                      <span className={styles.bold}> 3rd network layer</span>.
                    </p>
                    <p>
                      Then each of your 1,000
                      <span className={styles.bold}> 3rd network layer </span>
                      members refers 10 new friends. These 10,000 referrals make up your
                      <span className={styles.bold}> 4th network layer</span>.
                    </p>
                  <p>So, your 
                    <span className={styles.bold}> Total Downstream Network </span>
                    is the sum of all the members in all 4 of your network layers, which totals 11,110 downstream network members!!</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  How can Buygoon help your business?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>If you have a social network, website, blog, newsletter, App or email list, then you likely have many friends you can invite to join Buygoon. Read above how only 10 friend referrals could grow into a network of 11,110 people. If you have dozens, hundreds or thousands of friends to refer to Buygoon, your network could grow amazingly large. All of those people in your downstream network will generate cash bonues for you on the cash back they earn while shopping online at Buygoon.</p>
                  <p>Buygoon lets you seamlessly and conveniently integrate reward-based shopping, which should help you to build follower loyalty and your cash back bonus revenue. You can motivate greater downstream network shopping, and increase your cash back bonus potential, if you share the deals, coupons and sales we offer, with all of your referrals and suggest that they share, and so on.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  Can Buygoon help me fundraise?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Yes. Whether you are a charity or other non-profit fund raising organization, once you have joined Buygoon, distributing your unique referral link to your members can be a powerful way to raise funds, because when they all sign up with your link, they will enter your downstream network, and you will earn a cash bonus on all the cash back they earn shopping online.</p>
                  <p>Alternatively, if you are a person looking for ways to be charitable, you can of course withdraw your cash back to your PayPal account and then donate it to your chosen charity.</p>
                  <p>Read above about how large your downstream network can grow and how we can help grow your fund raising with rewards-based shopping.</p>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>

          <div className={styles.accordionContainer}>
            <h2>Cash Back, Reports & Payments</h2>

            <Accordion collapsible className={styles.accordion}>
              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  Where do I see my cash back and cash bonus earnings?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>
                  <span>When signed in to your account, in the top right corner, click on your name and then </span>
                    <span className={styles.bold}>My Cash </span>
                    in the drop down menu. It will show you your total cash available for withdrawal and the amount of cash back still pending, both from your own shopping and from your downstream network’s shopping.
                  </p>
                  <p>Initially all your cash back remains in pending status. Total Cash Pending are those transactions that we have tracked as valid and have been acknowledged by the store, but are still within the store's return or cancellation period and therefore cannot yet be paid out to you. Once the return or cancellation period of your purchase is over, the store should pay us in the next monthly payment cycle and your cash should be available for withdrawal. You will then see the amount in the Total Cash Available for Withdrawal section.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  How long does it take to receive cash back on my own purchases?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Timing of cash back payment varies from store to store. Cash back will generally be calculated on a monthly basis and then be available for withdrawal by you from your Buygoon Account page via PayPal. Exactly when cash back will be available will depend on when our retail partners actually pay us on your purchase. Those stores will usually wait for their full product return or cancellation period to expire before they pay us, which is usually 30 to 120 days after your purchase. Please note that we can only share the cash back we actually receive from the store, once we actually receive it.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  How do I get paid my cash back?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Buygoon has partnered with PayPal to make withdrawals of your cash rewards both convenient and secure. Once a store pays us the cash back on your purchase, we make it available to you in your Buygoon account. When you want to withdraw the cash, simply click the button to initiate payment from Buygoon to your PayPal account. The payment is processed according to PayPal’s payment policy, but you should receive the money into your PayPal account within 7-10 days. All cash back payments are generated in Canadian dollars. Any PayPal charges, including things like currency exchange charges, will be applied by PayPal to your account according to their standard policies.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  Does cash in my Buygoon account expire?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>No! You can leave cash in your Buygoon account as long as you wish.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  Can I use Buygoon if I live outside the U.S. and Canada?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Yes, so long as the store you purchase from will ship to your location outside of the U.S. and Canada and pays cash back to your country. Please check the given store's policy on Buygoon.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  Can I earn cash back if I navigate to a store directly and send you my receipt?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Unfortunately not. You must visit the store by clicking through from a link on Buygoon in order to earn cash back. It is the only way the store can track your purchase and calculate the cash bonus. When the store finalizes the transaction, it will pay Buygoon cash back, which Buygoon shares with you.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  Do I earn cash back if I return or cancel a purchase or make an exchange?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>No. Ultimately, exchanges, returns and cancellations are subject to the policy of the store from which you made your purchase. If you return or cancel a purchase, Buygoon will not be credited cash back and we can therefore have nothing to pay to you. If we have received the cash back before your cancellation or return, we will have to refund the store and then reverse the cash back amount from your account.</p>
                  <p>Exchanges will usually also reverse cash back rewards. In such a case, the store will usually cancel the original product order and replace it with a new one, which does not respect the referral link from Buygoon, and therefore, will not credit us cash back. To avoid losing cash back on an exchange, we recommend you return the product for a refund and make a new purchase transaction via Buygoon.</p>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>

          <div className={styles.accordionContainer}>
            <h2>Troubleshooting</h2>

            <Accordion collapsible className={styles.accordion}>
              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  How do I inquire about a purchase on which I have not received any cash back?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>If you would like to inquire about a transaction, please wait at least 48 hours and send us a message via Contact Us, including all the transaction details (i.e. date, time, store, order number, amount of each purchase, total purchase before tax and shipping). Please be sure to print and keep all of your shopping receipts and order number confirmations provided by our stores. We will endeavour to follow up with the store to get your missing cash back. While most purchases are processed by stores and reported to us within 24-48 hours, some stores may take a month or more to report a transaction. We will not be able to process and display your rebates until/unless the store reports to us.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  How long does it take to resolve my cash back inquiry?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>We do our best to follow up with our retail partners, but cash back inquiries with them can take up to 3 months to resolve. Please recall, that our compensation also depends on your shopping transactions being properly tracked and rewarded, so you can rest assured that we are as motivated as you to chase cash back from stores.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  What happens if my cash back query is denied?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Our store partners have sole discretion in determining the validity of and resolving each cash back inquiry. We will do our best to help resolve your cash back inquiry favourably, but in some cases it may be declined. Unfortunately, once a store rejects a cash back inquiry, its decision is final.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  Why might a purchase transaction not track or track incorrectly?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Although rare, a store may fail to track a transaction. Some of the most common reasons a purchase may not be tracked properly by a store are: (i) you did not sign in to your Buygoon account and click through one of our advertisements; (ii) you did not comply with the terms and conditions listed on our website and/or the store's website; (iii) cookies were disabled on your computer; (iv) another website was awarded the cash back bonus; (v) a product voucher code or gift code not authorized by our website was used; (vi) the purchase was returned or cancelled; (vii) the purchase was exchanged or the order was amended; (viii) the purchase was not completed wholly online; (ix) the product is excluded from cash back by the store; or (x) the store has ceased business.</p>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  What are some best practices for ensuring cash back is properly tracked?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <ul>
                    <li>(i) Log into your Buygoon account and click through one of our links each time you make a purchase and complete your full purchase before you browse to another website;</li>
                    <li>(ii) Do not click through Buygoon to one of our stores, and then browse another website, and then return to the store to make a second purchase, as your second purchase may not earn a cash back reward;</li>
                    <li>(iii) If you use multiple windows on your browser, ensure the window in which you shop at the store opened from a Buygoon link;</li>
                    <li>(iv) Permanently allow cookies, JavaScript and third party images in your browser settings;</li>
                    <li>(v) Disable page caching on your browser so that it always opens the newest version of Buygoon and store webpages;</li>
                    <li>(vi) Turn off "content blocking" on your browser;</li>
                    <li>(vii) Do not type in a store name at the top of your browser screen – please only follow links from Buygoon;</li>
                    <li>(viii) Do not use any comparison site or any code, link or voucher provided by any other website (i.e. a comparison site, other cash back site, etc.), as the store may pay that site instead of Buygoon, in which case we cannot pay you cash back; and</li>
                    <li>(ix) Complete your purchase online, because, if you make a purchase over the telephone or in-store, we cannot pay you cash back.</li>
                  </ul>  
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton className={styles.accordionButton}>
                  What can I do if a store link is not working?
                </AccordionButton>

                <AccordionPanel className={styles.accordionPanel}>
                  <p>Please make sure you have enabled cookies, disabled ad blockers and tried another browser before investigating further.</p>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default Help
