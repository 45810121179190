import React, { useState } from 'react'
import cx from 'classnames'
import { Col, Row, Visible } from 'react-grid-system'

import styles from './Category.module.scss'

import Button from '../Button'
import Layout from '../Layout'

const Category = ({ categories, category, alphabets, retailers: ALL_STORES, storesDetails }) => {
  const [filteredStores, setRetailers] = useState(ALL_STORES)
  const [filter, setFilter] = useState('all')
  const [seeMore, setSeeMore] = useState(false)
  const isStoresEmpty = filteredStores.length === 0;
  const isCategoryDescriptionEmpty = !category.description || category.description.length === 0;
  const findStoreDetailsBySlug = (slug) => storesDetails.find((store) => store.slug === slug)

  return (
    <Layout>
      <Row>
        <Visible lg xl xxl>
          <Col lg={4}>
            <div className={styles.categoriesMenu}>
              <h3 className={styles.categoriesTitle}>Categories</h3>

              <ul className={styles.categoriesMenuList}>
                {categories.map((category) => {
                  return (
                    <li key={category.id} className={styles.categoriesMenuListItem}>
                      {window.location.pathname === `/categories/${category.slug}` && (
                        <div>{category.name}</div>
                      )}

                      {window.location.pathname !== `/categories/${category.slug}` && (
                        <a href={`/categories/${category.slug}`}>
                          {category.name}
                        </a>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
          </Col>
        </Visible>

        <Col lg={8}>
          <div className={styles.categoriesContainer}>
            <div className={styles.breadCrumb}>
              <a href="/categories">All Categories</a>
              <span className={styles.breadCrumbDivider}> / </span>
              <div>{category.name}</div>
            </div>

            <div className={styles.descriptionContainer}>
              <h1>{category.name}</h1>
              
              {!isCategoryDescriptionEmpty && (
                <div>
                  <div className={cx(styles.description, { [styles.seeMore]: seeMore })}>
                    {category.description}
                  </div>

                  <div className={styles.buttonContainer}>
                    {seeMore && <button className={styles.contentToggle} onClick={() => setSeeMore(false)}>See Less</button>}
                    {!seeMore && <button className={styles.contentToggle} onClick={() => setSeeMore(true)}>See More</button>}
                  </div>
                </div>
              )}
            </div>

            {!isStoresEmpty && (
              <div>
                <div className={styles.header}>
                  <div className={styles.alphabetContainer}>
                    <div
                      className={cx(styles.letter, { [styles.selected]: filter === 'all' })}
                      onClick={() => {
                        setFilter('all')
                        setRetailers(ALL_STORES)
                      }}
                    >
                      All
                    </div>

                    {alphabets.map((letter) => {
                      if (letter === '#') return

                      return (
                        <div
                          key={letter}
                          className={cx(styles.letter, { [styles.selected]: filter === letter })}
                          onClick={() => {
                            const filteredRetailers = ALL_STORES.filter(({ name }) => name.startsWith(letter))
                            setFilter(letter)
                            setRetailers(filteredRetailers)
                          }}
                        >
                          {letter}
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className={styles.retailers}>
                  {filteredStores.map((retailer) => {
                    const storeDetails = findStoreDetailsBySlug(retailer.slug)

                    return (
                      <a key={retailer.slug} href={`${storeDetails.home_page_link || '/stores/' + retailer.slug}`} className={styles.retailerLink} target="_blank">
                        <div className={styles.retailerCard}>
                          <div className={styles.retailer}>
                            <div className={styles.brand}>
                              <img src={`${retailer.logo_url || 'https://picsum.photos/100/47'}`} className={styles.logo} />
                            </div>

                            <div className={styles.cardContainer}>
                              {storeDetails && (
                                <>
                                  <div className={styles.button}>
                                    <object>
                                      <Button variant="secondary" href={`${storeDetails.home_page_link || '/stores/' + retailer.slug}`} target="_blank">
                                        Shop Now
                                      </Button>
                                      
                                      {storeDetails && storeDetails.home_page_link_info && storeDetails.home_page_link_info.impression_pixel && (
                                        <img src={storeDetails.home_page_link_info.impression_pixel} />
                                      )}                          
                                    </object>
                                  </div>
                                  
                                  <div className={styles.cashBack}>
                                    {storeDetails.display_commission_string_html}
                                  </div>

                                  <a href={`/stores/${retailer.slug}`} className={styles.seeInfo}>
                                    See Info & Coupons
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </a>
                    )
                  })}
                </div>
              </div>
            )}

            {isStoresEmpty && (
              <div className={styles.header}>
                <div className={styles.moreStoresContainer}>
                  We're working hard to partner with the best stores. Please check back later.
                </div>
              </div>
            )}

          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default Category