import React from 'react'
import cx from 'classnames'

import {
  ListboxInput,
  ListboxButton,
  ListboxList,
  ListboxOption,
  ListboxPopover,
} from '@reach/listbox'

import '@reach/listbox/styles.css'
import styles from './Select.module.scss'

// import ArrowSvg from '../Icons/arrow.svg'

const ArrowSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0.41 1 11.18 6.59">
      <path
        d="M2.11997 1.29L5.99997 5.17L9.87997 1.29C10.27 0.899998 10.9 0.899998 11.29 1.29C11.68 1.68 11.68 2.31 11.29 2.7L6.69997 7.29C6.30997 7.68 5.67997 7.68 5.28997 7.29L0.699971 2.7C0.309971 2.31 0.309971 1.68 0.699971 1.29C1.08997 0.909998 1.72997 0.899998 2.11997 1.29Z"
        fill="currentColor"
      />
    </svg>
  )
}

const Select = ({
  portal = true,
  labelId,
  label,
  initialValue,
  placeholder,
  options,
  disabled,
  onChange,
}) => {
  return (
    <div className={styles.selectContainer}>
      {label && (
        <label htmlFor={labelId} className={styles.label}>
          {label}
        </label>
      )}
      
      <ListboxInput
        aria-labelledby={labelId}
        value={initialValue === '' ? placeholder : initialValue}
        onChange={(value) => onChange({ value })}
        className={cx(styles.listboxInput, {
          [styles.disabled]: disabled,
        })}
        disabled={disabled}
      >
        <ListboxButton arrow={<ArrowSvg />} className={styles.listboxButton} />
        <ListboxPopover portal={portal} className={styles.listboxPopover}>
          <ListboxList>
            {placeholder && (
              <ListboxOption disabled value={placeholder}>
                {placeholder}
              </ListboxOption>
            )}

            {options.map(({ value, name }) => {
              return (
                <ListboxOption key={value} value={value}>
                  {name}
                </ListboxOption>
              )
            })}
          </ListboxList>
        </ListboxPopover>
      </ListboxInput>
    </div>
  )
}

export default Select
